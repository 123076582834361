import "../css/style.css";
//import Loginform from "../components/Login/loginform";

import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { FormattedMessage } from "react-intl"; //多國語言套件
import { getAuthToken } from "../global/TokenUtil";
import Swal from "sweetalert2";
import { API_BarcodeATM } from "../global/constants"
import { checkAuthToken } from "../global/TokenUtil";
function Applyrefund() {
    const history = useHistory();
    const queryParams = new URLSearchParams(window.location.search);
    const path = queryParams.get("path");
    const hide = { 'display':'none' };
    const show = { 'display': 'block' };
    const URegID = queryParams.get("URegID");
    useEffect(() => {
        document.title = "臺中市政府文化局報名繳費系統--申請退費";
        //if (localStorage.getItem("UserData") == null) {
        //    localStorage.setItem("preUrl", document.baseURI);
        //    window.location = "/IdentityVerify";
        //    return;
        //}
        
        if (getAuthToken() == "xxx") {
            //alert();
            Swal.fire("憑證過期，請重新登入。", "", "error").then(ok => {
                localStorage.setItem("preUrl", document.baseURI);
                window.location = "/IdentityVerify";
            });
            
        }
        if (URegID == null || URegID == "") {
            history.goBack();
        }


        API_BarcodeATM({ URegID: URegID })
            .then((response) => response.data)
            .then((data) => {
                checkAuthToken(data);
                if (data.code == "0000") {
                    if (data.result != null) {
                        Swal.fire({
                            icon: "info",
                            title: "依簡章規定計算，實際退費金額以審核結果為主。審核通過後，將通知至現場進行退費。",
                            text: "報名金額：" + data.result.payMoney + "元",
                        });
                    }
                    else {
                        Swal.fire(data.message, "", "error").then
                            ((res) => {
                                window.history.back();
                            });
                    }
                }
                else {
                    Swal.fire(data.message, "", "error").then
                        ((res) => {
                            window.history.back();
                        });
                }
            })
            .catch(err => {
                console.log(err);
            })


  
        

  },[]);

  return (
      <>
          <section className="section-box">
              <div className="container">
                  <div> <a accesskey="C" href="#C" id="AC" name="C" title="本頁主要內容" class="a-acceky">:::</a></div>

                  <h1 className="text-center">申請退費</h1>


                  <div className="form-box">
                      <div className="form-container">

                          <div className="text-center">

                              <img src="images/apply-refund.svg" />

                              <p className="my-4 mb-4 mb-md-5">確認要申請退費，申請後無法取消，確認要申請退費嗎？</p>

                              <div className="form-item btn-area">
                                  <button type="button" onClick={() => { history.push('/StudentSearch'); }} className="btn-line btn-primary mr-2">取消申請</button>
                                  <button type="button" onClick={() => { history.push('/Applyrefundconfirm?URegID=' + URegID); }} className="btn btn-primary">確定申請</button>
                              </div>

                          </div>

                      </div>
                      {/*<!-- end form-container -->*/}
                  </div>
                  {/*<!-- end form-box -->*/}


              </div>
          </section>
     </>
  );
}
export default Applyrefund;
